<template>
  <div>
    <!--begin::Content header-->
    <div
      class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
      <span class="font-weight-bold font-size-3 text-dark-60">
        {{ $t("login.donothaveaccount") }}
      </span>
      <router-link class="font-weight-bold font-size-3 ml-2" :to="{ name: 'register', hash: '#projects' }">{{
        $t("login.register") }}
      </router-link>
    </div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-10 mb-lg-20">
        <h3 class="font-size-h1">{{ $t("login.login") }}</h3>
        <p class="text-muted font-weight-semi-bold">
          {{ $t("login.enteruserpassword") }}
        </p>
      </div>

      <!--begin::Form-->
      <form @submit.prevent="handleSubmit" class="form">

        <div role="alert" v-if="loginError" class="alert fade alert-danger">
          <div class="alert-text">
            {{ loginErrorText }}
          </div>
        </div>

        <b-form-group id="example-input-group-1" label="" label-for="example-input-1">
          <v-text-field v-model="username" :rules="[rules.required]" required label="User Name" type="text">
          </v-text-field>
        </b-form-group>

        <b-form-group id="example-input-group-2" label="" label-for="example-input-2">

          <v-text-field id="password" class="green-input" v-model="password" label="Password" :rules="[rules.required]"
            :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'" />
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
          <!-- <a
            href="#"
            class="text-dark-60 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            Forgot Password ?
          </a> -->
          <button ref="kt_login_signin_submit" class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3">
            {{ $t("login.login") }}
          </button>
        </div>
        <!--end::Action-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>

import { setAuthToken } from '@/components/utils/auth'

export default {
  name: "setAuthToken",
  data() {
    return {
      value: String,
      loading: false,
      loginError: false,
      loginErrorText: '',
      username: '',
      password: '',
      submitted: false,
      status: {
        loggingIn: false
      },
      rules: {
        required: value => !!value || 'Required Field',
      },
    };
  },
  computed: {
    customerNumber() {
      return this.$store.state.userDetails.subscriptionId;
    },
  },

  methods: {
    showErrorMessage(message) {
      this.$buefy.notification.open({
        duration: 5000,
        message: message,
        position: 'is-top-right',
        type: 'is-danger',
        hasIcon: true
      })
    },


    async handleSubmit() {
      this.loginError = false;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      try {
        //clear previous login customer info from shared data
        this.$store.commit('resetUserDetails');
        this.$axios({
          url: `${this.$baseURL}/Account`,
          method: 'POST',
          data: {
            username: this.username,
            password: this.password,
          },
          grant_type: 'password'
        })
          .then((result) => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            console.log(result);
            if (JSON.parse(result.data.status) == true) {
              setAuthToken(result.data.token.token)
              this.$store.commit({
                type: "changeUserName",
                userName: this.username
              });
              this.$store.commit({
                type: "changeUserID",
                userName: result.data.token.username
              });
              // this.$store.dispatch("getUserDetails",this.username);
              //  this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' +result.data.token.token;
              this.$store.commit('updateUserDetails', result.data.userDetails.value.userDetails)


              if (result.data.userDetails.value.userDetails.subscriptionId > 0) {
                this.$router.push({ path: '/Customer/CustomerDashboard' });
              }
              else {
                this.$router.push({ path: '/AdminDashboard' });
              }

            }
            else {
              this.showErrorMessage(result.data.message);
              this.password = '';
            }


          });



        //          loginError:false,
        // loginErrorText:'',

      } catch (err) {
        this.showErrorMessage(err); this.password = '';
      }
    }

  }
}
</script>
